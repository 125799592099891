import { useUserSession } from "../hooks/useUserSession";
import { mergeClasses } from "../lib/tools/helpers";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";
import useMobile from "../hooks/useMobile";
/**
 * MainBodyWrapper component.
 * This component is used to wrap the body so that we can access the global recoil states
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @param {boolean} props.chat - Indicates if chat is active.
 * @param {boolean} props.miniSide - Indicates if mini side bar is active.
 * @param {boolean} props.sports - Indicates if sports page is active.
 * @returns {JSX.Element} The rendered MainBodyWrapper component.
 */
export default function MainBodyWrapper({
  children,
  chat,
  miniSide,
  sports,
  mobileLanding,
}) {
  const { hasUserData } = useUserSession();
  const pathname = usePathname();
  const isChallengesPage = pathname === "/challenges";

  return (
    <motion.div
      animate={{
        opacity: [0.8, 1],
      }}
      transition={{
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
        type: "spring",
        stiffness: 100,
        damping: 20,
      }}
      {...mergeClasses(
        "main-body-container transition-[width] duration-300",
        chat && "active-chat",
        miniSide && "mini-side-bar",
        sports && "sports-page",
        hasUserData && "logged-in",
        mobileLanding && "mobile-landing",
        isChallengesPage && "challenges-page-container"
      )}
    >
      {children}
    </motion.div>
  );
}
